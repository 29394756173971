import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import MultiSelect from 'react-multiple-select-dropdown-lite'
import MultiSelect from 'multiselect-react-dropdown';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";


const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};


const Add= () => {

const url = process.env.REACT_APP_URL;


  const [image, setImage] = useState({ raw: "", preview: "" });
  const [state, setState] = useState({
    name: "",
    status:""
  });
  const {
    name,
    status
  } = state;




  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);


  useEffect(() => {
  }, []);



  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }




const create = async (e) => {
      e.preventDefault();
    if (!name && !status) {
       alert("Please Provide All  Required Input Fields");
      } else {
            let formdata = new FormData();
            formdata.append("name", name);
            formdata.append("status", status);
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" }, 
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/facilities/create`,
              })
              if(res.status==200)
              {
                console.log(res)
                navigate(-1);
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };


  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add New Facility </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center pt-5">
                        <div class="mb-3  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Facility Name *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Name"
                            value={name}
                            name="name"
                            onChange={handleChange}
                          />
                        </div>

                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Staus *:
                          </label>
                          <select
                            type="text"
                            class="form-control"
                            value={status}
                            name="status"
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                          </select>
                        </div>
                       
                      </div>

                      {/* <div className="row justify-content-center ">
                        <div class="mmb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Image/Picture *:
                          </label>
                          <input
                            type="file"
                            multiple
                            name="images"
                            className="form-control"
                            placeholder="name"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                          {image.preview ? (
                            <img
                              src={image.preview}
                              alt=""
                              height="100"
                              width="100"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                       
                      </div> */}
                      <div className="row justify-content-center">
                        <div class="mmb-3  col-md-3 formFields">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Create 
                        </button>
                         </div>
                        <div class="mmb-3  col-md-7 formFields">
                        </div>
                      </div>
                      <div className="row justify-content-center pt-5 ">
                      </div>

                    </>
                  )}
                </>
              </div>

              <div className="card-body text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
