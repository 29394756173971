import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import MultiSelect from 'react-multiple-select-dropdown-lite'
import MultiSelect from 'multiselect-react-dropdown';
import Loader from "../../components/Shared/Loader";
import Message from "../../components/Shared/Message";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";


import { convertToHTML } from 'draft-convert';
// import jwtDecode from "jwt-decode";

const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const SamundramPackage = () => {

const url = process.env.REACT_APP_URL;

  const [image, setImage] = useState({ raw: "", preview: "" });
  const [state, setState] = useState({
    package_name: "",
    package_short_description: "",
    package_description: "",
    package_slug:"",
    starting_price: "",
    cost_price: "",
    status: "",
    transport_charges:"0",
    other_charges:"0",
    ship_name:""
  });

  const {
    package_name,
    package_short_description,
    package_description,
    package_slug,
    starting_price,
    cost_price,
    status,
    transport_charges,
    other_charges,
    ship_name
  } = state;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  


  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  // const [editorStateADF, setADFEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState)
  //   const contentHTML = convertToHTML(editorState.getCurrentContent());

  //   console.log('...' + contentHTML);

  //   setState({ ...state, ['description']: contentHTML });
  // }
  // const onADFEditorStateChange = (editorState) => {
  //   setADFEditorState(editorState)
  //   const contentHTML = convertToHTML(editorState.getCurrentContent());

  //   console.log('...' + contentHTML);

  //   setState({ ...state, ['additional_facility_id']: contentHTML });
  // }
  

  useEffect(() => {
  }, []);



  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      console.log(image.raw);
    }
  };

 
 const create = async (e) => {
      e.preventDefault();
    if (!package_name && !status) {
       alert("Please Provide All  Required Input Fields");
      } else {

            let formdata = new FormData();
            formdata.append("package_name", package_name);
            formdata.append("package_slug", package_slug);
            formdata.append("package_short_description", package_short_description);
            formdata.append("package_description", package_description);
            formdata.append("starting_price", starting_price);
            formdata.append("cost_price", cost_price);
            formdata.append("status", status);
            formdata.append("transport_charges", transport_charges);
            formdata.append("other_charges", other_charges);
            formdata.append("ship_name", ship_name);
              if (image.raw !== null && image.raw !== "") {
                formdata.append("image", image.raw);
              }
              else
              {
                formdata.append("image", '');

              }
           
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" },
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/samundram-package/createpackage`,
              })
              if(res.status==200)
              {
                console.log(res)
                navigate(-1);
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };


  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add New Package </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center pt-5">
                        <div class="mb-5  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Samundram Package Name *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Package Name"
                            value={package_name}
                            name="package_name"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-3 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Ship Name *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Ship Name"
                            value={ship_name}
                            name="ship_name"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-2  col-md-2 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Staus *:
                          </label>
                          <select
                            type="text"
                            class="form-control"
                            value={status}
                            name="status"
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                          </select>


                        </div>

                      </div>

                      <div className="row justify-content-center">
                      <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Package Offer Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Offer price"
                            required
                            value={starting_price}
                            name="starting_price"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Package Cost Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Cost price"
                            required
                            value={cost_price}
                            name="cost_price"
                            onChange={handleChange}
                          />
                        </div>

                      </div>
                      <div className="row justify-content-center">
                      <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Transport Charges Per/Person*:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Transport Charges Per/Person"
                            required
                            value={transport_charges}
                            name="transport_charges"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                           Other Charges Per/Person if any *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Other Charges Per/Person"
                            required
                            value={other_charges}
                            name="other_charges"
                            onChange={handleChange}
                          />
                        </div>

                      </div>
                      
                      
                      <div className="row justify-content-center ">
                        {/* <div class="mmb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Package  Image/Picture *:
                          </label>
                          <input
                            type="file"
                            multiple
                            name="images"
                            className="form-control"
                            placeholder="name"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                          {image.preview ? (
                            <img
                              src={image.preview}
                              alt=""
                              height="100"
                              width="100"
                            />
                          ) : (
                            ""
                          )}
                        </div> */}


                      </div>
                   
                      <div className="row justify-content-center">
                        <div class="mmb-3  col-md-3 formFields">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Create Package
                        </button>
                         </div>
                        <div class="mmb-3  col-md-7 formFields">
                        </div>
                      </div>
                      <div className="row justify-content-center pt-5 ">
                      </div>

                    </>
                  )}
                </>
              </div>

              <div className="card-body text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SamundramPackage;
