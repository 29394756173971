import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const Hotelmages = () => {
const urlapi = process.env.REACT_APP_URL;

  const [hotelImages, setHotelImages] = useState([]);
  const [images, setImages] = useState([]);
  const [fileurl, setFileUrls] = useState([]);

  let fileObj = [];
  let fileArray = [];
  let fileArrayupload = [];

  const { id } = useParams();

  useEffect(() => {
    fetchhotelimages();
  }, []);


  const fetchhotelimages = async () => {
    try {
      const response = await axios.get(`${urlapi}/api/admin/hotels/gethotelimages/`+id);
      setHotelImages(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };
  function arrayBufferToBase64Imageurl(buffer) {
    var base64Flag = 'data:image/jpeg;base64,';
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    var windowbtao= window.btoa(binary);
   var finalimage= base64Flag + windowbtao;
   return finalimage;
}





  let navigate = useNavigate();
  function handleClick() {
   navigate(-1);
  }
 



  const handleImageChange = (e) => {

    fileObj.push(e.target.files)
    setImages(e.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      // fileArrayupload.push(e.target.files[i]);
    }
    // setImages(fileArrayupload);

    setFileUrls(fileArray);

  };
 
  const removeImage = async (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${urlapi}/api/admin/hotels/deletehotelimage/`+id,
          })
          if(res.status==200)
          {
            fetchhotelimages();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });

  };

  
  const create = async () => {
    if (images !== null && images !== "") {
      let formdata = new FormData();
      formdata.append("hotel_id", id);
      for (let i = 0; i < images.length; i++) {
        formdata.append("images", images[i]);
      }

      try {
        const res = await axios({
          headers: { "Content-Type": "multipart/form-data" },
          method: "POST",
          data:formdata,
          // withCredentials: true,
          url: `${urlapi}/api/admin/hotels/uploadhotelimages`,
        })
        if(res.status==200)
        {
          // console.log(res)
          // navigate(-1);
          window.location.reload();
        }
        } catch (error) {
          console.error("Error submitting data:", error);
        }
        
      // dispatch(createHotelImages(formdata, id));
      // handleClick();

    } else {
      // toast.warning("Please Provide All Input Fields");
    }
  };

  return (
    <div className="page-body">
      <div className="container-fluid"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12" style={Top}>
            <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Hotel Image </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="">
                      <button type="button" class="btn btn-primary" onClick={handleClick}>
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <div className="row justify-content-center pt-5">
                  <div class="mb-3  col-md-7 formFields ">
                      <label
                        for="floatigSelectGrid"
                        class="form-label"
                        name="images"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        Add Hotel Images (max 10 images in once):
                      </label>
                      <input
                        type="file"
                        multiple
                        name="images"
                        className="form-control"
                        placeholder="name"
                        onChange={handleImageChange}
                        accept="image/*"
                        
                      />
                    </div>
                    <div class="mb-3  col-md-3 formFields ">
                      <button style={{ margin: "30px" }} type="button" class="btn btn-primary" onClick={create}>
                        Upload:
                      </button>
                    </div>
                </div>
                <div className="row justify-content-center pt-5">
                      <div class="mb-10  col-md-12 formFields ">
                        {(fileurl || []).map(url => (
                          <img src={url} alt="..." style={{ height: "200px", width: "200px", margin: "8px" }} />
                        ))}
                      </div>
                </div>
                 
                <div className="row justify-content-center pt-5">
                      <div class="mb-10  col-md-12 imageswrap">
                        <h5 style={{ textAlign: "center" }}>Existing Images</h5>
                        {(hotelImages || []).map((item, i) => (
                       
                        <div  style={{ display: "inline-block" }} className="info" key = {item.id}>
                            <span className="close" onClick= {()=>removeImage(item.id)} >
                                <span
                                  className="inner-x">
                                  &times;
                                </span>
                              </span>
                          <img src={arrayBufferToBase64Imageurl(item.image.data)} alt={item.id} style={{ height: "200px", width: "200px", margin: "8px" }} />
                        </div>
                            ))}

                      </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>

      <div className="card-body text-center"></div>
    </div>
  );
};

export default Hotelmages;
