import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";

const url = process.env.REACT_APP_URL;


const GetCallback = () => {
  const [callback, setCallback] = useState([]);

  // Fetch all callbacks on component mount
  useEffect(() => {
    fetchCallback();
  }, []);

  // Fetch all callbacks from the API
  const fetchCallback = async () => {
    try {
      const response = await axios.get(`${url}/api/callback/get`);
      setCallback(response.data);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching callbacks:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Callback Customer Enquiry</h3>

      {/* Table to display callbacks */}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>destination</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {callback.map((call) => (
            <tr key={call.id}>
              <td>{call.name}</td>
              <td>{call.destination}</td>
              <td>{call.email}</td>
              <td>{call.mobile_number}</td>
              <td>{call.callback_date}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default GetCallback;
