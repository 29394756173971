import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import MultiSelect from 'react-multiple-select-dropdown-lite'
import MultiSelect from 'multiselect-react-dropdown';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";


import { convertToHTML } from 'draft-convert';
// import jwtDecode from "jwt-decode";

const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const AddHotel = () => {

const url = process.env.REACT_APP_URL;

  const [HotelProprtyType, setHotelProprtyType] = useState([]);
  const [facility, setFacility] = useState([]);
  const [ameneties, setAmeneties] = useState([]);
  const [Selectedfacility, setSelectedFacility] = useState([]);
  const [Selectedameneties, setSelectedAmeneties] = useState([]);
  const [Hoteldestination, setHoteldestination] = useState([]);
  const [hoteldestinationid, setShoteldestinationid] = useState("");


  const [image, setImage] = useState({ raw: "", preview: "" });
  const [state, setState] = useState({
    name: "",
    hotel_type_id: "",
    mobile: "",
    email: "",
    website: "",
    geo_location: "",
    starting_price: "",
    cost_price: "",
    destination_id: "",
    pincode: "",
    starting_price: "",
    address: "",
    landmark: "",
    amenities_id: [],
    facility_id: [],
    short_description: "",
    description: "",
    notes: "",
    status: "",
    hotel_star:"5",
    booking_after_days:"0",
    transport_charges:"0",
    other_charges:"0"
  });

  const {
    name,
    hotel_type_id,
    mobile,
    email,
    website,
    geo_location,
    starting_price,
    cost_price,
    destination_id,
    pincode,
    address,
    landmark,
    amenities_id,
    facility_id,
    short_description,
    description,
    notes,
    status,
    hotel_star,
    booking_after_days,
    transport_charges,
    other_charges
  } = state;




  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  



  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  // const [editorStateADF, setADFEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState)
  //   const contentHTML = convertToHTML(editorState.getCurrentContent());

  //   console.log('...' + contentHTML);

  //   setState({ ...state, ['description']: contentHTML });
  // }
  // const onADFEditorStateChange = (editorState) => {
  //   setADFEditorState(editorState)
  //   const contentHTML = convertToHTML(editorState.getCurrentContent());

  //   console.log('...' + contentHTML);

  //   setState({ ...state, ['additional_facility_id']: contentHTML });
  // }
  







  // useEffect(() => {

  //   console.log('...', HotelProprtyType)

  // }, []);

  useEffect(() => {
    fetchDataHotelProperty();
    fetchDataHotelFacilities();
    fetchDataHotelAmenities();
    fetchDataHoteldestination();
  }, []);


  const fetchDataHotelProperty = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelpropertytype`);
      // console.log('----?', response.data)
      setHotelProprtyType(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };
  const fetchDataHotelFacilities = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelfacilities`);
      console.log('----?', response.data.results)
      setFacility(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelFacilities:", error);
    }
  };
  const fetchDataHotelAmenities = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelamenities`);
      // console.log('----?', response.data)
      setAmeneties(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelAmenities:", error);
    }
  };
  const fetchDataHoteldestination = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhoteldestination`);
      // console.log('----?', response.data)
      setHoteldestination(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHoteldestination:", error);
    }
  };
  

const onSelectAmeneties= (selectedList, selectedItem) => {
    console.log('sel..', selectedList)
    setSelectedAmeneties(selectedList)
    setState({ ...state, ['amenities_id']: selectedList });

}

const onRemoveAmeneties = (selectedList, removedItem) => {
  console.log('rem..', selectedList)
  setSelectedAmeneties(selectedList)
  setState({ ...state, ['amenities_id']: selectedList});
}

const onSelectFacilities= (selectedList, selectedItem) => {
  console.log('sel..', selectedList)
  setSelectedFacility(selectedList)
  setState({ ...state, ['facility_id']: selectedList });


}
const onRemoveFacilities = (selectedList, removedItem) => {
console.log('rem..', selectedList)
setSelectedFacility(selectedList)
setState({ ...state, ['facility_id']: selectedList });

}

const handleHoteldesctination = (e) => {
  setShoteldestinationid(e.target.value);
  setState({ ...state, ['destination_id']: e.target.value });
};


  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      console.log(image.raw);
    }
  };

 

    const create = async (e) => {
      e.preventDefault();
    if (!name && !hotel_type_id && !mobile && !geo_location && !starting_price && !cost_price && !destination_id && !pincode && !address && !short_description && !description && !status && !image.raw) {
       alert("Please Provide All  Required Input Fields");
      } else {

      // console.log("name",name);
      // console.log("hotel_type_id",hotel_type_id);
      // console.log("starting_price",starting_price);
      // console.log("destination_id",destination_id);
      // console.log("address",address);
           var amenities_idrcv =amenities_id.map(u => u.id).join(',')
           var facility_idrcv =facility_id.map(u => u.id).join(',')
            console.log("with image");
            let formdata = new FormData();
            formdata.append("name", name);
            formdata.append("hotel_type_id", hotel_type_id);
            formdata.append("mobile", mobile);
            formdata.append("email", email);
            formdata.append("website", website);
            formdata.append("geo_location", geo_location);
            formdata.append("starting_price", starting_price);
            formdata.append("cost_price", cost_price);
            formdata.append("destination_id", destination_id);
            formdata.append("pincode", pincode);
            formdata.append("address", address);
            formdata.append("landmark", landmark);
            formdata.append("amenities_id", amenities_idrcv);
            formdata.append("facility_id", facility_idrcv);
            formdata.append("short_description", short_description);
            formdata.append("description", description);
            formdata.append("notes", notes);
            formdata.append("status", status);
            formdata.append("hotel_star", hotel_star);
            formdata.append("booking_after_days", booking_after_days);
            formdata.append("transport_charges", transport_charges);
            formdata.append("other_charges", other_charges);

           
              if (image.raw !== null && image.raw !== "") {
                formdata.append("image", image.raw);
              }
              else
              {
                formdata.append("image", '');

              }
           
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" },
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/hotels/createhotel`,
              })
              if(res.status==200)
              {
                console.log(res)
                navigate(-1);
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };


  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add New Hotels </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center pt-5">
                        <div class="mb-3  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Name *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Name"
                            value={name}
                            name="name"
                            onChange={handleChange}
                          />
                        </div>

                        <div class="mb-3 col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Property Type *:
                          </label>
                          <select
                            type="text"
                            class="form-control"
                            value={hotel_type_id}
                            name="hotel_type_id"
                            onChange={handleChange}
                          >
                            <option value="">Hotel Property Type</option>
                            {HotelProprtyType.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>

                        </div>
                      
                       
                      </div>



                      <div className="row justify-content-center">
                        <div class="mb-3 col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Contact No *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="+91********"
                            value={mobile}
                            name="mobile"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3 col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Email Address:
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="abc@gmail.com"
                            value={email}
                            name="email"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div class="mb-3 col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Website:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="www.abc.com"
                            value={website}
                            name="website"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Geo Location Url*:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Geo Location Url"
                            required
                            value={geo_location}
                            name="geo_location"
                            onChange={handleChange}
                          />
                        </div>

                      </div>
                      <div className="row justify-content-center">
                      <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Offer Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Offer price"
                            required
                            value={starting_price}
                            name="starting_price"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Cost Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Cost price"
                            required
                            value={cost_price}
                            name="cost_price"
                            onChange={handleChange}
                          />
                        </div>

                      </div>

                  <div className="row justify-content-center ">

                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <div class="mb-3  col-md-5 formFields">

                      <label
                        for="floatingSelectGrid"
                        className="form-label"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        Select Island/Place *:
                      </label>

                      <select
                        name="hoteldestinationid"
                        value={hoteldestinationid}
                        onChange={handleHoteldesctination}
                        className="form-control"
                      >
                        <option value="">Select Island/Place</option>
                        {Hoteldestination &&
                          Hoteldestination.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}

                  <div class="mb-3  col-md-5 formFields ">
                    <label
                      for="exampleFormControlInput1"
                      class="form-label"
                    >
                      PINCODE *:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Pincode"
                      value={pincode}
                      name="pincode"
                      onChange={handleChange}
                    />
                  </div>
                  </div>

                  <div className="row justify-content-center ">
                        <div class="mb-3  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Address *:
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Address"
                            value={address}
                            name="address"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Landmark:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Landmark"
                            value={landmark}
                            name="landmark"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center ">
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Amenities:
                          </label>
                          <MultiSelect
                            options={ameneties}
                            displayValue="name"
                            selectedValues={Selectedameneties} // Preselected value to persist in dropdown
                            onSelect={onSelectAmeneties} // Function will trigger on select event
                            onRemove={onRemoveAmeneties}
                          />
                        </div>

                        <div class="mb-3 col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Facalitiese:
                          </label>

                          <MultiSelect
                            options={facility}
                            displayValue="name"
                            selectedValues={Selectedfacility} // Preselected value to persist in dropdown
                            onSelect={onSelectFacilities} // Function will trigger on select event
                            onRemove={onRemoveFacilities} Options to display in the dropdown
                          />


                        </div>
                      </div>
                   
                
                     <div className="row justify-content-center ">
                      <div class="mb-4  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Booking  after days :
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Booking Start after days"
                            value={booking_after_days}
                            name="booking_after_days"
                            onChange={handleChange}
                          />
                        </div>

                       <div class="mb-4  col-md-4 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Transport Charges Per/Person :
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Transport Charges Per/Person"
                            value={transport_charges}
                            name="transport_charges"
                            onChange={handleChange}
                          />
                        </div>

                        <div class="mb-4  col-md-4 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Per/Person Other Charges if any :
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Other Charges if any"
                            value={other_charges}
                            name="other_charges"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center ">
                       
                      <div class="mb-10  col-md-10 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Short description *:
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Short Description"
                            value={short_description}
                            name="short_description"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center ">
                       
                      <div class="mb-10  col-md-10 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Description *:
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Description"
                            value={description}
                            name="description"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center ">
                       
                       <div class="mb-10  col-md-10 formFields ">
                           <label
                             for="exampleFormControlInput1"
                             class="form-label"
                           >
                             Addition Notes/Documents Required:
                           </label>
                           <textarea
                             type="text"
                             class="form-control"
                             id="exampleFormControlInput1"
                             placeholder="notes"
                             value={notes}
                             name="notes"
                             onChange={handleChange}
                           />
                         </div>
                       </div>

                      {/* <div className="row justify-content-center ">
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Additional Facility:
                          </label>
                          <div className="texteditor">

                            <Editor editorState={editorStateADF} onEditorStateChange={onADFEditorStateChange} />
                          </div>
                        </div>

                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Description About:
                          </label>

                          <div className="texteditor">

                            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                          </div>


                        </div>
                      </div> */}

                      <div className="row justify-content-center">


                      </div>
                      <div className="row justify-content-center ">
                        <div class="mmb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Main Image/Picture *:
                          </label>
                          <input
                            type="file"
                            multiple
                            name="images"
                            className="form-control"
                            placeholder="name"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                          {image.preview ? (
                            <img
                              src={image.preview}
                              alt=""
                              height="100"
                              width="100"
                            />
                          ) : (
                            ""
                          )}
                        </div>


                        <div class="mb-3  col-md-3 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Staus *:
                          </label>
                          <select
                            type="text"
                            class="form-control"
                            value={status}
                            name="status"
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                          </select>


                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Star *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Hotel Start"
                            value={hotel_star}
                            name="hotel_star"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                   
                      <div className="row justify-content-center">
                        <div class="mmb-3  col-md-3 formFields">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Create Hotel
                        </button>
                         </div>
                        <div class="mmb-3  col-md-7 formFields">
                        </div>
                      </div>
                      <div className="row justify-content-center pt-5 ">
                      </div>

                    </>
                  )}
                </>
              </div>

              <div className="card-body text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHotel;
