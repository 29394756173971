import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Button, Overlay } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';

import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';


const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};


const HotelBookingList = () => {
  const navigation = useNavigate();
  const url = process.env.REACT_APP_URL;
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchbookinglist();
  }, []);

  const fetchbookinglist = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelbooking`);
      console.log('----?', response.data.results)
      setResult(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };
  // const hotel = useSelector((state) => state.Hotels);

  // const { loading, error, result } = hotel;


  
  const handleroutes = (e) => {
    navigation(`/hotel-update/${e.id}`, { state: e });
  };
  const handleDelete = (hotelid) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${url}/api/admin/hotels/delete/`+hotelid,
          })
          if(res.status==200)
          {
            // console.log(res)
            fetchbookinglist();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

 
  const columns = [
    // const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "#",
      headerName: "SN",
      width: 20,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    },

    { field: "hotel_name", headerName: "Hotel ", width: 170 },
    { field: "room_name", headerName: "Room", width: 140 ,},
    // { field: "booking_id", headerName: "booking_id" },
      {
      field: "booking_id",
      headerName: "booking_id",
      width: 100,
      renderCell: (params) => (
        <div className="linkbtnhoteldiv">
          <NavLink to={`/hotel-booking-detail/${params.row.id}`}> {params.row.booking_id} </NavLink>
        </div>
      ),
    },
    {
      field: "check_in_date",
      headerName: "CheckIn",
      width: 95,
      // renderCell: (params) => moment.utc(params.row.check_in_date).format('DD/MM/YYYY')
    },
    {
      field: "check_out_date",
      headerName: "CheckOut",
      width:95,
      // renderCell: (params) => moment.utc(params.row.check_out_date).format('DD/MM/YYYY')
    },
    {
      field: "no_of_rooms",
      headerName: "Rooms",
      width: 65,
    },
    // {
    //   field: "no_of_adults",
    //   headerName: "Adults",
    //   width: 60,
    // },
    {
      field: "full_name",
      headerName: "Name",
      width: 130,
    },
    {
      field: "phone",
      headerName: "Phone",
      // width: 130,
    },

    {
      field: "total_booking_amount",
      headerName: "Amount",
      width: 80,
    },
    {
      field: "status_name",
      headerName: "Status",
      width: 80,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 95,
      renderCell: (params) => moment.utc(params.row.created_at).format('DD/MM/YYYY')
    },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   // width: 130,
    //   renderCell: (params) => (
    //     <div>
    //       <EditIcon onClick={() => handleroutes(params.row)} />
    //       {/* <PreviewIcon onClick={() => handleroutes(params.row)} /> */}
    //       <DeleteIcon onClick={() => handleDelete(params.row.id)} />
    //     </div>
    //   ),
    // },
   
    // {
    //   field: "View",
    //   headerName: "View/Update",
    //   width: 60,
    //   renderCell: (params) => (
    //     <div className="linkbtnhoteldiv">
    //       <NavLink to={`/rooms/${params.row.id}`}> View </NavLink>
    //     </div>
    //   ),
    // },
  
  ];


  <div>

  </div>
  return (
    <>
      <div className="page-body"  >
        {/* <ToastContainer /> */}
        <div className="container-fluid">
        

          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card">
                <div className="card-header d-flex" style={btnrt}>
                  <h5>All Hotels Booking </h5>
                </div>

                <div className="text-center">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      {/* <DataGrid
                        data={result}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                      /> */}
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>

    </>
  );
};

export default HotelBookingList;
