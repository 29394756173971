import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import MultiSelect from 'multiselect-react-dropdown';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";


import { convertToHTML } from 'draft-convert';
// import jwtDecode from "jwt-decode";

const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const AddHotel = () => {

  const { id } = useParams();

const url = process.env.REACT_APP_URL;

  const [ameneties, setAmeneties] = useState([]);
  const [Selectedameneties, setSelectedAmeneties] = useState([]);

  const [image, setImage] = useState({ raw: "", preview: "" });
  const [state, setState] = useState({
    hotel_id: "",
    name: "",
    no_of_sleep: "",
    price: "",
    cost_price: "",
    amenities_id: [],
    inclusions: "",
    description: "",
    no_of_available_rooms: 1,
    status: "",
    max_child:"2"

  });

  const {
    hotel_id,
    name,
    no_of_sleep,
    price,
    cost_price,
    amenities_id,
    inclusions,
    description,
    no_of_available_rooms,
    status,
    max_child
  } = state;




  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);



  useEffect(() => {
    fetchDataHotelAmenities();
  }, []);

 
  const fetchDataHotelAmenities = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelamenities`);
      // console.log('----?', response.data)
      setAmeneties(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelAmenities:", error);
    }
  };
  const onSelectAmeneties= (selectedList, selectedItem) => {
    console.log('sel..', selectedList)
    setSelectedAmeneties(selectedList)
    setState({ ...state, ['amenities_id']: selectedList });

}

const onRemoveAmeneties = (selectedList, removedItem) => {
  console.log('rem..', selectedList)
  setSelectedAmeneties(selectedList)
  setState({ ...state, ['amenities_id']: selectedList});
}



  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      console.log(image.raw);
    }
  };

 

    const create = async (e) => {
      e.preventDefault();
    if (!name  && !no_of_sleep  && !price && !cost_price  && !description && !status && !image.raw) {
       alert("Please Provide All  Required Input Fields");
      } else {
           var amenities_idrcv =amenities_id.map(u => u.id).join(',')

            console.log("with image");
            let formdata = new FormData();
            formdata.append("hotel_id", id);
            formdata.append("name", name);
            formdata.append("no_of_sleep", no_of_sleep);
            formdata.append("price", price);
            formdata.append("cost_price", cost_price);
            formdata.append("amenities_id", amenities_idrcv);
            formdata.append("inclusions", inclusions);
            formdata.append("description", description);
            formdata.append("no_of_available_rooms", no_of_available_rooms);
            formdata.append("status", status);
            formdata.append("max_child", max_child);

            
              if (image.raw !== null && image.raw !== "") {
                formdata.append("image", image.raw);
              }
              else
              {
                formdata.append("image", '');

              }
           
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" },
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/rooms/create`,
              })
              if(res.status==200)
              {
                console.log(res)
                navigate(-1);
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };


  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add New Room </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center pt-5">
                        <div class="mb-3  col-md-6 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Room Type Name *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Name"
                            value={name}
                            name="name"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Max Adult Allowed *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="No. of Sleep"
                            value={no_of_sleep}
                            name="no_of_sleep"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Max Child Allowed *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Max Child allowed"
                            value={max_child}
                            name="max_child"
                            onChange={handleChange}
                          />
                        </div>
                        
                      </div>

                      <div className="row justify-content-center">
                      <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Room Offer Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Offer price"
                            required
                            value={price}
                            name="price"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Room Cost Price *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Cost price"
                            required
                            value={cost_price}
                            name="cost_price"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center ">

                      <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Amenities:
                          </label>
                          <MultiSelect
                            options={ameneties}
                            displayValue="name"
                            selectedValues={Selectedameneties} // Preselected value to persist in dropdown
                            onSelect={onSelectAmeneties} // Function will trigger on select event
                            onRemove={onRemoveAmeneties}
                          />
                        </div>
                        <div class="mb-3 col-md-5 formFields">
                        <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Inclusions *:
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Inclusions"
                            value={inclusions}
                            name="inclusions"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                     
                      <div className="row justify-content-center ">
                        <div class="mb-10  col-md-10 formFields ">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Description *:
                            </label>
                            <textarea
                              type="text"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Description"
                              value={description}
                              name="description"
                              onChange={handleChange}
                            />
                          </div>
                      </div>

                      {/* <div className="row justify-content-center pt-5">
                        <div class="mb-3  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            No of Rooms *:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="No. of Rooms"
                            value={no_of_available_rooms}
                            name="no_of_available_rooms"
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      <div className="row justify-content-center pt-5">
                        <div class="mmb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Hotel Main Image/Picture *:
                          </label>
                          <input
                            type="file"
                            multiple
                            name="images"
                            className="form-control"
                            placeholder="name"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                          {image.preview ? (
                            <img
                              src={image.preview}
                              alt=""
                              height="100"
                              width="100"
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="mb-3  col-md-5 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Staus *:
                          </label>
                          <select
                            type="text"
                            class="form-control"
                            value={status}
                            name="status"
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                          </select>


                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div class="mmb-3  col-md-3 formFields">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Create Room
                        </button>
                         </div>
                        <div class="mmb-3  col-md-7 formFields">
                        </div>
                      </div>
                      <div className="row justify-content-center pt-5 ">
                      </div>

                    </>
                  )}
                </>
              </div>

              <div className="card-body text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHotel;
