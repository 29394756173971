// components/Sidebar.js
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaChevronDown,
  FaChevronUp,
  FaSuitcase,
  FaBlog,
  FaInfoCircle,
  FaHotel,
  FaList
} from "react-icons/fa";
import "../Styles/sidebar.css";

const Sidebar = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isPackageOpen, setIsPackageOpen] = useState(false);

  // coded by salman start

  const [isHotelsOpen, setIsPackageHotels] = useState(false);
  const [isSamundramPackagesOpen, setIsSamundramPackagesOpen] = useState(false);

  // coded by salman end

  const toggleBannerLinks = () => {
    setIsBannerOpen(!isBannerOpen);
  };

  const togglePackageLinks = () => {
    setIsPackageOpen(!isPackageOpen);
  };

  // coded by salman start
  const toggleHotelsLinks = () => {
    setIsPackageHotels(!isHotelsOpen);
  };
  const toggleSamundramPackages = () => {
    setIsSamundramPackagesOpen(!isSamundramPackagesOpen);
  };
  // coded by salman end

  return (
  <div className="sidebar bg-#bfc0ce text-dark vh-100 p-3">
      <Nav defaultActiveKey="/" className="flex-column">
        <Nav.Link as={Link} to="/" className="text-dark">
          <FaHome className="me-2" /> Dashboard
        </Nav.Link>

        <div>
          <Nav.Link
            as={Link}
            to="/"
            className="text-dark"
            onClick={toggleBannerLinks}
          >
            <FaHome className="me-2" /> Banners{" "}
            {isBannerOpen ? <FaChevronUp /> : <FaChevronDown />}
          </Nav.Link>
          {isBannerOpen && (
            <>
              <Nav.Link as={Link} to="/banner/main" className="text-dark ms-4">
                Main Banner
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/banner/honeymoon"
                className="text-dark ms-4"
              >
                Honeymoon Banner
              </Nav.Link>
              <Nav.Link as={Link} to="/banner/coral" className="text-dark ms-4">
                Coral Banner
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/banner/advertisement"
                className="text-dark ms-4"
              >
                Advertisement Banner
              </Nav.Link>
            </>
          )}
        </div>
        <div>
          <Nav.Link
            as={Link}
            to="/"
            className="text-dark"
            onClick={togglePackageLinks}
          >
            <FaSuitcase className="me-2" /> Packages{" "}
            {isPackageOpen ? <FaChevronUp /> : <FaChevronDown />}
          </Nav.Link>
          {isPackageOpen && (
            <>
              <Nav.Link
                as={Link}
                to="/package/packagecard"
                className="text-dark ms-4"
              >
                Package Card
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/package/packagedetail"
                className="text-dark ms-4"
              >
                Package Detail
              </Nav.Link>
            </>
          )}
        </div>

        <Nav.Link as={Link} to="/blogs" className="text-dark">
          <FaBlog className="me-2" /> Blogs
        </Nav.Link>
        <Nav.Link as={Link} to="/blogdetail" className="text-dark">
          <FaBlog className="me-2" /> Blog Detail
        </Nav.Link>
        <Nav.Link as={Link} to="/about" className="text-dark">
          <FaInfoCircle className="me-2" /> About Us
        </Nav.Link>
        <Nav.Link as={Link} to="/callback" className="text-dark">
          <FaInfoCircle className="me-2" /> Get Callback
        </Nav.Link>
         {/* coded by salman start */}

    <div>
        <Nav.Link
          as={Link}
          to="/"
          className="text-dark"
          onClick={toggleHotelsLinks}
        >
          <FaHotel className="me-2" /> Hotels{" "}
          {isHotelsOpen ? <FaChevronUp /> : <FaChevronDown />}
        </Nav.Link>

        {isHotelsOpen && (
          <>

            <Nav.Link as={Link} to="/destinationslist" className="text-dark ms-4">
              <FaList className="me-2" /> Destinations{" "}
            </Nav.Link>
            <Nav.Link as={Link} to="/amenitieslist" className="text-dark ms-4">
              <FaList className="me-2" /> Amenities{" "}
            </Nav.Link>
            <Nav.Link as={Link} to="/facilitieslist" className="text-dark ms-4">
              <FaList className="me-2" /> Facilities{" "}
            </Nav.Link>
            <Nav.Link as={Link} to="/hotelslist" className="text-dark ms-4">
              <FaList className="me-2" /> Hotels{" "}
            </Nav.Link>
            <Nav.Link as={Link} to="/hotelbookinglist" className="text-dark ms-4">
              <FaList className="me-2" /> Booking List{" "}
            </Nav.Link>
          </>
        )}
      </div>
      <div>
          <Nav.Link
            as={Link}
            to="/"
            className="text-dark"
            onClick={toggleSamundramPackages}
          >

            <FaHotel className="me-2" /> Samundram{" "}
            {isSamundramPackagesOpen ? <FaChevronUp /> : <FaChevronDown />}
          </Nav.Link>
          {isSamundramPackagesOpen && (
            <>
              <Nav.Link as={Link} to="/samundram-packages" className="text-dark ms-4">
                <FaList className="me-2" />Samundram Packages List{" "}
              </Nav.Link>
              <Nav.Link as={Link} to="/samundram-bookink-list" className="text-dark ms-4">
                <FaList className="me-2" />Samundram Booking List{" "}
              </Nav.Link>
              
            </>
          )}
        </div>
        
 {/* coded by salman end */}
      </Nav>
    </div>

  
  );
};

export default Sidebar;
