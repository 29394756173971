import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Loader from "../../components/Shared/Loader";
import Message from "../../components/Shared/Message";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Margin } from "@mui/icons-material";
import moment from 'moment';

const btnrt = {
    justifyContent: "space-between",
  };
  
  const Top = {
    paddingTop: "10px",
  };
  const btnTop = {
    paddingTop: "30px",
  };
  

const DateCharts = ({ hotelId }) => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [hotel, sethotel] = useState({});

  const [Hoteldestination, setHoteldestination] = useState([]);
  const [hoteldestinationid, setShoteldestinationid] = useState("");
  const [state, setState] = useState({
    samundram_package_id: "",
    destination_id: "",
    seq_no: "",
    journey_date: "",
  });

  const {
    samundram_package_id,
    destination_id,
    seq_no,
    journey_date,
  } = state;

  
  useEffect(() => {
     fetchalldatechart();
     fetchPackagedetailsbyid(id)
     fetchDestination();
  }, [id]);

  const fetchPackagedetailsbyid = async (id) => {
    try {
      const response = await axios.get(`${url}/api/admin/samundram-package/get/`+id);
      sethotel(response.data);
    } catch (error) {
      console.error("Error fetching data fetchHoteldetailsbyid:", error);
    }
  };

  const fetchalldatechart = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/samundram-package/datechartgetall/`+id);
      console.log('----?', response.data.results)
      setResult(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };

  
  const handleroutes = (e) => {
    navigation(`/room-update/${e.id}`, { state: e });
  };

  const handleDelete = (room_id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${url}/api/admin/samundram-package/datechartdelete/`+room_id,
          })
          if(res.status==200)
          {
            // console.log(res)
            fetchalldatechart();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  
  const fetchDestination = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhoteldestination`);
      // console.log('----?', response.data)
      setHoteldestination(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHoteldestination:", error);
    }
  };
  const handleHoteldestination = (e) => {
    setShoteldestinationid(e.target.value);
    setState({ ...state, ['destination_id']: e.target.value });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
    const create = async (e) => {
      e.preventDefault();
    if (!hoteldestinationid  && !journey_date && !seq_no) {
       alert("Please Provide All  Required Input Fields");
      } else {
            
            let formdata = new FormData();
            formdata.append("samundram_package_id", id);
            formdata.append("destination_id", hoteldestinationid);
            formdata.append("seq_no", seq_no);
            formdata.append("journey_date", journey_date);
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" },
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/samundram-package/datechartcreate`,
              })
              if(res.status==200)
              {
                console.log(res)
                // navigate(-1);
                fetchalldatechart();
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };

  const columns = [
    // {
    //   field: "#",
    //   headerName: "SN",
    //   width: 50,
    //   renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    // },
    {
      field: "seq_no",
      headerName: "Seq No",
      width: 150,
    },
    {
        field: "destination",
        headerName: "destination",
        width: 350,
      },
      {
        field: "journey_date",
        headerName: "Journey Date",
        width: 250,
        renderCell: (params) => moment.utc(params.row.journey_date).format('DD/MM/YYYY')
      },
    {
      field: "Action",
      headerName: "Details",
      width: 90,
      renderCell: (params) => (
        <div>
          {/* <EditIcon onClick={() => handleroutes(params.row)} /> */}
          <DeleteIcon onClick={() => handleDelete(params.row.id)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card">
        <div className="card-header d-flex" style={btnrt}>
          <h5>Samundram :-  {hotel.package_name} Package </h5>
          {/* <NavLink to={`/samundram-date-charts-create/${id}`}>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-original-title="test"
              data-bs-target="#exampleModal"
            >
              Add Date chart
            </button>
          </NavLink> */}
          <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
        </div>

        <div className="text-center">
        {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
                    </>
          )}
        </div>
      </div>
      <div className="card">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add Date Chart </h5>
               
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center">
                      {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <div class="mb-4  col-md-3 formFields">

                      <label
                        for="floatingSelectGrid"
                        className="form-label"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        Select Island/Place *:
                      </label>

                      <select
                        name="hoteldestinationid"
                        value={hoteldestinationid}
                        onChange={handleHoteldestination}
                        className="form-control"
                      >
                        <option value="">Select Island/Place</option>
                        {Hoteldestination &&
                          Hoteldestination.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                        <div class="mb-3  col-md-3 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Journey Date *:
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Journey Date"
                            value={journey_date}
                            name="journey_date"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-3 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Seq No *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Seq No"
                            required
                            value={seq_no}
                            name="seq_no"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mmb-3  col-md-2 formFields" style={btnTop}> 
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Add Date Chart
                        </button>
                         </div>
                      </div>

                    </>
                  )}
                </>
              </div>

            </div>
          </div>
        </div>
    </>
  );
};

export default DateCharts;
