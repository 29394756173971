import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import Blogs from "./pages/Blogs";
import AboutUs from "./pages/AboutUs";
import MainBanner from "./pages/Banners/MainBanner";
import CoralBanner from "./pages/Banners/CoralBanner";
import HoneymoonBanner from "./pages/Banners/HoneymoonBanner";
import AdvertisementOne from "./pages/Banners/AdvertisementOne";
import BlogDetail from "./pages/BlogDetail";
import Header from "./components/Header";
import Login from "./pages/Login";
import PackagesCard from "./pages/Packages/PackagesCard";
import GetCallback from "./pages/GetCallback";
import TravelPackageForm from "./pages/Packages/TravelPackageForm";

// coded by salman start

import  Destinations from "./pages/HotelDashboard/Destinations/Index";
import  DestinationsAdd from "./pages/HotelDashboard/Destinations/Add";
import  DestinationsEdit from "./pages/HotelDashboard/Destinations/Edit";

import  Amenities from "./pages/HotelDashboard/Amenities/Index";
import  AmenitiesAdd from "./pages/HotelDashboard/Amenities/Add";
import  AmenitiesEdit from "./pages/HotelDashboard/Amenities/Edit";

import  Facilities from "./pages/HotelDashboard/Facilities/Index";
import  FacilitiesAdd from "./pages/HotelDashboard/Facilities/Add";
import  FacilitiesEdit from "./pages/HotelDashboard/Facilities/Edit";

import Hotels from "./pages/HotelDashboard/Hotels/Hotels";
import  HotelsAdd from "./pages/HotelDashboard/Hotels/AddHotels";
import  HotelsEdit from "./pages/HotelDashboard/Hotels/EditHotel";
import  HotelImages from "./pages/HotelDashboard/Hotels/Hotelmages";
import  HotelBookingList from "./pages/HotelDashboard/Hotels/HotelBookingList";
import  HotelBookingDetails from "./pages/HotelDashboard/Hotels/HotelBookingDetails";


import  Rooms from "./pages/HotelDashboard/Rooms/Rooms";
import  RoomAdd from "./pages/HotelDashboard/Rooms/AddRooms";
import  RoomEdit from "./pages/HotelDashboard/Rooms/EditRooms";

import  SamundramPackage from "./pages/SamundramPackage/SamundramPackage";
import  AddSamundramPackage from "./pages/SamundramPackage/AddSamundramPackage";
import  EditSamundramPackage from "./pages/SamundramPackage/EditSamundramPackage";
import  DateCharts from "./pages/SamundramPackage/DateCharts";
import  AddDateCharts from "./pages/SamundramPackage/AddDateCharts";
import  SamundramBookingList from "./pages/SamundramPackage/SamundramBookingList";
import  SamundramBookingDetail from "./pages/SamundramPackage/SamundramBookingDetail";

// coded by salman end


function App() {
  const [token, setToken] = useState(false);

  // This effect runs only once when the component mounts
  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    setToken(!!savedToken); // Set token to true if it exists
    console.log(!!savedToken); // Log the existence of the token
  }, []); // Empty dependency array to run this effect only once

  // If there is no token, render the Login component
  if (!token) {
    return <Login />;
  } else {
    // If there is a token, render the main application
    return (
      <div>
        <Header />
        <Container fluid className="d-flex">
          <Sidebar />
          <Container fluid className="p-4">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/" element={<Login />} />
              <Route path="/banner/main" element={<MainBanner />} />
              <Route path="/banner/honeymoon" element={<HoneymoonBanner />} />
              <Route path="/banner/coral" element={<CoralBanner />} />
              <Route
                path="/banner/advertisement"
                element={<AdvertisementOne />}
              />
              <Route path="/package/packagecard" element={<PackagesCard />} />
              <Route
                path="/package/packagedetail"
                element={<TravelPackageForm />}
              />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogdetail" element={<BlogDetail />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/callback" element={<GetCallback />} />

            {/* coded by salman start */}
            <Route path="/destinationslist" element={<Destinations/>} />
              <Route path="/destination-create" element={<DestinationsAdd/>} />
              <Route path="/destination-update/:id" element={<DestinationsEdit/>} />

              <Route path="/amenitieslist" element={<Amenities/>} />
              <Route path="/amenity-create" element={<AmenitiesAdd/>} />
              <Route path="/amenity-update/:id" element={<AmenitiesEdit/>} />

              <Route path="/facilitieslist" element={<Facilities/>} />
              <Route path="/facility-create" element={<FacilitiesAdd/>} />
              <Route path="/facility-update/:id" element={<FacilitiesEdit/>} />
              
              <Route path="/hotelslist" element={<Hotels/>} />
              <Route path="/hotelbookinglist" element={<HotelBookingList/>} />
              <Route path="/hotel-booking-detail/:id" element={<HotelBookingDetails/>} />

              
              <Route path="/hotel-create" element={<HotelsAdd/>} />
              <Route path="/hotel-update/:id" element={<HotelsEdit/>} />
              <Route path="/hotel-images/:id" element={<HotelImages/>} />

              <Route path="/rooms/:id" element={<Rooms/>} />
              <Route path="/room-create/:id" element={<RoomAdd/>} />
              <Route path="/room-update/:id" element={<RoomEdit/>} />

              <Route path="/samundram-packages" element={<SamundramPackage/>} />
              <Route path="/samundram-package-create" element={<AddSamundramPackage/>} />
              <Route path="/samundram-package-update/:id" element={<EditSamundramPackage/>} />
              <Route path="/samundram-packages-datechart/:id" element={<DateCharts/>} />
              <Route path="/samundram-date-charts-create/:id" element={<AddDateCharts/>} />
              <Route path="/samundram-bookink-list" element={<SamundramBookingList/>} />
              <Route path="/samundram-bookink-detail/:id" element={<SamundramBookingDetail/>} />
            {/* coded by salman start */}

            </Routes>
          </Container>
        </Container>
      </div>
    );
  }
}

export default App;
