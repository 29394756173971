import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const btnrt = {
  justifyContent: "space-between",
};

const Rooms = ({ hotelId }) => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [hotel, sethotel] = useState({});

  
  useEffect(() => {
     fetchallRooms();
     fetchHoteldetailsbyid(id)
  }, [id]);

  const fetchHoteldetailsbyid = async (id) => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/get/`+id);
      sethotel(response.data);
    } catch (error) {
      console.error("Error fetching data fetchHoteldetailsbyid:", error);
    }
  };

  const fetchallRooms = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/rooms/getall/`+id);
      console.log('----?', response.data.results)
      setResult(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };

  
  const handleroutes = (e) => {
    navigation(`/room-update/${e.id}`, { state: e });
  };

  const handleDelete = (room_id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${url}/api/admin/rooms/delete/`+room_id,
          })
          if(res.status==200)
          {
            // console.log(res)
            fetchallRooms();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  

  const columns = [
    {
      field: "#",
      headerName: "SN",
      width: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    },
    {
      field: "name",
      headerName: "Room Name",
      width: 250,
    },
    // {
    //   field: "image",
    //   headerName: "Icon",
    //   width: 100,
    //   renderCell: (params) => (
    //     <img
    //       src={params.row.image}
    //       alt=""
    //       style={{ width: "50px", height: "50px" }}
    //     ></img>
    //   ),
    // },
    {
      field: "description",
      headerName: "Description",
      width: 350,
    },


    {
      field: "price",
      headerName: "Offer Price",
      width: 100,
    },
    {
      field: "cost_price",
      headerName: "Cost Price",
      width: 100,
    },

    // {
    //   field: "Inclusions",
    //   headerName: "Inclusions",
    //   width: 180,
    // },
    {
      field: "Status",
      headerName: "Status",
      width: 80,
      renderCell: (params) =>
        params.row.status !== 0 ? (
          <>Active</>
        ) : (
          <>In-Active</>
        ),
    },
    {
      field: "Action",
      headerName: "Details",
      width: 90,
      renderCell: (params) => (
        <div>
          <EditIcon onClick={() => handleroutes(params.row)} />
          <DeleteIcon onClick={() => handleDelete(params.row.id)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card">
        <div className="card-header d-flex" style={btnrt}>
          <h5>Hotel:-  {hotel.name}  Rooms </h5>
          <NavLink to={`/room-create/${id}`}>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-original-title="test"
              data-bs-target="#exampleModal"
            >
              Add Rooms
            </button>
          </NavLink>
        </div>

        <div className="text-center">
        {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
                    </>
          )}
        </div>
      </div>
    </>
  );
};

export default Rooms;
