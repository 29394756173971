import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';

import Loader from "../../components/Shared/Loader";
import Message from "../../components/Shared/Message";
import axios from "axios";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const SamundramPackage = () => {
  const navigation = useNavigate();
  const url = process.env.REACT_APP_URL;
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // dispatch(fetchallSamundramPackage());
    fetchallSamundramPackage();
  }, []);

  const fetchallSamundramPackage = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/samundram-package/getallpackages`);
      console.log('----?', response.data.results)
      setResult(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };
  // const hotel = useSelector((state) => state.Hotels);

  // const { loading, error, result } = hotel;


  
  const handleroutes = (e) => {
    navigation(`/samundram-package-update/${e.id}`, { state: e });
  };
  const handleDelete = (hotelid) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${url}/api/admin/samundram-package/delete/`+hotelid,
          })
          if(res.status==200)
          {
            // console.log(res)
            fetchallSamundramPackage();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

 
  const columns = [
    {
      field: "#",
      headerName: "SN",
      width: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    },
    { field: "package_name", headerName: "Package Name", width: 250 },
    {
      field: "price",
      headerName: "Offer Price",
      width: 100,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 80,
      renderCell: (params) =>
        params.row.status !== 0 ? (
          <>Active</>
        ) : (
          <>In-Active</>
        ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      // width: 130,
      renderCell: (params) => (
        <div>
          <EditIcon onClick={() => handleroutes(params.row)} />
          {/* <PreviewIcon onClick={() => handleroutes(params.row)} /> */}
          <DeleteIcon onClick={() => handleDelete(params.row.id)} />
        </div>
      ),
    },
    {
      field: "datechart ",
      headerName: "Date Chart",
      width: 220,
      renderCell: (params) => (
        <div className="linkbtnhoteldiv">
          <NavLink to={`/samundram-packages-datechart/${params.row.id}`}> Add/View Date Chart  </NavLink>
        </div>
      ),
    },
  
  ];


  <div>

  </div>
  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card">
                <div className="card-header d-flex" style={btnrt}>
                  <h5>All Samundram Packages </h5>
                  <Link to="/samundram-package-create">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-toggle="modal"
                      data-original-title="test"
                      data-bs-target="#exampleModal"
                    >
                      Add Samundram Packages
                    </button>
                  </Link>
                </div>

                <div className="text-center">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      {/* <DataGrid
                        data={result}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                      /> */}
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SamundramPackage;
