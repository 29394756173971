import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import MultiSelect from 'multiselect-react-dropdown';
import Loader from "../../components/Shared/Loader";
import Message from "../../components/Shared/Message";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";


import { convertToHTML } from 'draft-convert';
// import jwtDecode from "jwt-decode";

const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const AddDateCharts = () => {

  const { id } = useParams();

const url = process.env.REACT_APP_URL;

  const [Hoteldestination, setHoteldestination] = useState([]);
  const [hoteldestinationid, setShoteldestinationid] = useState("");
  const [state, setState] = useState({
    samundram_package_id: "",
    destination_id: "",
    seq_no: "",
    journey_date: "",
  });

  const {
    samundram_package_id,
    destination_id,
    seq_no,
    journey_date,
  } = state;




  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);



  useEffect(() => {
    fetchDestination();
  }, []);
  const fetchDestination = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhoteldestination`);
      // console.log('----?', response.data)
      setHoteldestination(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHoteldestination:", error);
    }
  };
  const handleHoteldestination = (e) => {
    setShoteldestinationid(e.target.value);
    setState({ ...state, ['destination_id']: e.target.value });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
    const create = async (e) => {
      e.preventDefault();
    if (!hoteldestinationid  && !journey_date && !seq_no) {
       alert("Please Provide All  Required Input Fields");
      } else {
            console.log("with image");
            let formdata = new FormData();
            formdata.append("samundram_package_id", id);
            formdata.append("destination_id", hoteldestinationid);
            formdata.append("seq_no", seq_no);
            formdata.append("journey_date", journey_date);
              try {
              const res = await axios({
                headers: { "Content-Type": "multipart/form-data" },
                method: "POST",
                data:formdata,
                // withCredentials: true,
                url: `${url}/api/admin/rooms/create`,
              })
              if(res.status==200)
              {
                console.log(res)
                navigate(-1);
              }
              } catch (error) {
                console.error("Error submitting data:", error);
              }
            }

  };


  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header d-flex" style={btnrt}>
                  <h5>Add Date Chart </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      <div className="row justify-content-center pt-5">
                      {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <div class="mb-3  col-md-3 formFields">

                      <label
                        for="floatingSelectGrid"
                        className="form-label"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        Select Island/Place *:
                      </label>

                      <select
                        name="hoteldestinationid"
                        value={hoteldestinationid}
                        onChange={handleHoteldestination}
                        className="form-control"
                      >
                        <option value="">Select Island/Place</option>
                        {Hoteldestination &&
                          Hoteldestination.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                        <div class="mb-3  col-md-3 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Journey Date *:
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Journey Date"
                            value={journey_date}
                            name="journey_date"
                            onChange={handleChange}
                          />
                        </div>
                        <div class="mb-3  col-md-3 formFields">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Seq No *:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Seq No"
                            required
                            value={seq_no}
                            name="seq_no"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                     

                      
                    
                      <div className="row justify-content-center">
                        <div class="mmb-3  col-md-3 formFields">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={create}
                        >
                          Create Room
                        </button>
                         </div>
                        <div class="mmb-3  col-md-7 formFields">
                        </div>
                      </div>
                      <div className="row justify-content-center pt-5 ">
                      </div>

                    </>
                  )}
                </>
              </div>

              <div className="card-body text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDateCharts;
