import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
const btnrt = {
  justifyContent: "space-between",
};

const Top = {
  paddingTop: "10px",
};

const Index = () => {
  const navigation = useNavigate();
  const url = process.env.REACT_APP_URL;
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    // dispatch(fetchallhotels());
    fetchalldata();
  }, []);

  const fetchalldata = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/amenities/getall`);
      console.log('----?', response.data.results)
      setResult(response.data.results);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };
  
  const handleroutesedit = (e) => {
    navigation(`/amenity-update/${e.id}`, { state: e });
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to delete this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
              try {
          const res = await axios({
            headers: { "Content-Type": "application/json" }, 
            method: "DELETE",
            // withCredentials: true,
            url: `${url}/api/admin/amenities/delete/`+id,
          })
          if(res.status==200)
          {
            // console.log(res)
            fetchalldata();
          }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });

  };

 

  const columns = [
    {
      field: "#",
      headerName: "Sn.No",
      // width: 75,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    },

    { field: "name", headerName: "Hotel Name", width: 250 },
    {
      field: "created_at",
      headerName: "Created At",
      width: 250,
      renderCell: (params) => moment.utc(params.row.created_at).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) =>
        params.row.status !== 0 ? (
          <>Active</>
        ) : (
          <>In-Active</>
        ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <div>
          <EditIcon onClick={() => handleroutesedit(params.row)} />
          {/* <PreviewIcon onClick={() => handleroutes(params.row)} /> */}
          <DeleteIcon onClick={() => handleDelete(params.row.id)} />
        </div>
      ),
    },
  
  ];


  <div>

  </div>
  return (
    <>
      <div className="page-body">
        {/* <ToastContainer /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={Top}>
              <div className="card">
                <div className="card-header d-flex" style={btnrt}>
                  <h5>All Amenities</h5>
                  <Link to="/amenity-create">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-toggle="modal"
                      data-original-title="test"
                      data-bs-target="#exampleModal"
                    >
                      Add Amenity
                    </button>
                  </Link>
                </div>

                <div className="text-center">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                      
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
