import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Loader from "../../../components/Shared/Loader";
import Message from "../../../components/Shared/Message";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';

const btnrt = {
  justifyContent: "space-between",
};
const minheight = {
  "min-height": "900px",
};

const HotelBookingDetails = ({ hotelId }) => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [hotelBookingDetail, sethotelBookingDetail] = useState({});

  
  useEffect(() => {
     fetchallPersons();
     fetchHoteldetailsbyid(id)
  }, [id]);

  const fetchHoteldetailsbyid = async (id) => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallhotelbookingdetail/`+id);
      sethotelBookingDetail(response.data);
    } catch (error) {
      console.error("Error fetching data fetchHoteldetailsbyid:", error);
    }
  };

  const fetchallPersons = async () => {
    try {
      const response = await axios.get(`${url}/api/admin/hotels/getallbookingpersonbybookingid/`+id);
      console.log('----?', response.data)
      setResult(response.data);
    } catch (error) {
      console.error("Error fetching data fetchDataHotelProperty:", error);
    }
  };

  
  const handleroutes = (e) => {
    navigation(`/room-update/${e.id}`, { state: e });
  };

  

  

  const columns = [
    {
      field: "#",
      headerName: "SN",
      width: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
   
    {
      field: "age",
      headerName: "Age",
      width: 50,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 80,
    },
    {
      field: "nationality",
      headerName: "Nationality",
      width: 150,
    },
    {
      field: "adhaar",
      headerName: "Adhaar",
      width: 200,
    },
    {
      field: "contact",
      headerName: "Phone",
      width: 110,
    },
    {
      field: "food_choice",
      headerName: "Food",
      width: 100,
    },
  ];
  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
  return (
    <>
      <div className="card" style={minheight}>
        
        <div className="card-header d-flex" style={btnrt}>
                  <h5>Booking Details:-  {hotelBookingDetail?.hotel_name}  ({hotelBookingDetail?.booking_id}) </h5>
                  <div style={{ marginLeft: "50%" }}>
                    <NavLink to="/GuestList">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClick}
                      >
                        back
                      </button>
                    </NavLink>
                  </div>
                </div>

                <div className="row justify-content-center">
                        <div class="mb-3  col-md-4 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Room 
                          </label>
                          <span class="form-control">{hotelBookingDetail?.room_name}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            check in date 
                          </label>
                          <span class="form-control">{hotelBookingDetail?.check_in_date}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            check out date  
                          </label>
                          <span class="form-control">{hotelBookingDetail?.check_out_date}</span>
                        </div>
                        
                        <div class="mb-3  col-md-1 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                           Rooms
                          </label>
                          <span class="form-control">{hotelBookingDetail?.no_of_rooms}</span>
                        </div>
                        <div class="mb-3  col-md-1 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                           Adults
                          </label>
                          <span class="form-control">{hotelBookingDetail?.no_of_adults}</span>
                        </div>
                        <div class="mb-3  col-md-1 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Child  
                          </label>
                          <span class="form-control">{hotelBookingDetail?.no_of_child}</span>
                        </div>
                </div>
                <div className="row justify-content-center">
                        <div class="mb-3  col-md-5 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Booking By Name 
                          </label>
                          <span class="form-control">{hotelBookingDetail?.full_name}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Booking By phone
                          </label>
                          <span class="form-control">{hotelBookingDetail?.phone}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                           Booking Date
                          </label>
                          <span class="form-control">{ moment.utc(hotelBookingDetail?.created_at).format('DD/MM/YYYY')}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                           Booking Status
                          </label>
                          <span class="form-control">{hotelBookingDetail?.status_name}</span>
                        </div>
                   
                       
                </div>
                <div className="row justify-content-center">
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Total Booked Amount
                          </label>
                          <span class="form-control">{hotelBookingDetail?.total_booking_amount}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Total Paid Amount
                          </label>
                          <span class="form-control">{hotelBookingDetail?.booking_amount_paid}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Total Pending Amount
                          </label>
                          <span class="form-control">{hotelBookingDetail?.booking_amount_pending}</span>
                        </div>
                        <div class="mb-3  col-md-1 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                             Subtotal
                          </label>
                          <span class="form-control">{hotelBookingDetail?.booking_amount}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                             Transport charges
                          </label>
                          <span class="form-control">{hotelBookingDetail?.total_extra_travel_booking_amount}</span>
                        </div>
                        <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                             Other Charges
                          </label>
                          <span class="form-control">{hotelBookingDetail?.total_extra_other_booking_amount}</span>
                        </div>
                </div>
                <div className="row justify-content-center">
                     <div class="mb-3  col-md-2 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Booking id
                          </label>
                          <span class="form-control">{hotelBookingDetail?.booking_id}</span>
                        </div>
                     
                        <div class="mb-3  col-md-3 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Payment order id
                          </label>
                          <span class="form-control">{hotelBookingDetail?.razory_pay_order_id}</span>
                        </div>

                        <div class="mb-3  col-md-3 formFields ">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Payment transection id
                          </label>
                          <span class="form-control">{hotelBookingDetail?.razorpay_payment_id}</span>
                        </div>
                        <div class="mb-3  col-md-3 formFields ">
                        </div>
                  
                </div>
        <div className="text-center">
        {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message />
                  ) : (
                    <>
                       <div className="row justify-content-center">
                        <div class="mb-10  col-md-11 formFields ">
                      <h5>Booking Persons Details</h5>
                     
      <DataGrid
        rows={result}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
         </div>
         </div>
                    </>
          )}
        </div>
      </div>
    </>
  );
};

export default HotelBookingDetails;
